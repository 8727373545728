import React from 'react'
import orads1pic from '../../images/popups/ORADS 1.png';
import orads2pic from '../../images/popups/ORADS 2.png';
import orads3pic from '../../images/popups/ORADS 3.png';
import orads4pic from '../../images/popups/ORADS 4.png';
import orads5pic from '../../images/popups/ORADS 5.png';
import './OradsPopups.css';
import { useNavigate } from 'react-router-dom';


export const Orads1 = () => {
  const navigate = useNavigate()

  const reset = () => {
    navigate('/');
  }
    return (
      <div>
        <button className='reset-btn' onClick={reset}>Reset</button>
        <img src={orads1pic} alt='orads1' className='orads-popup' />
      </div>
    )
}

export const Orads2 = () => {
  const navigate = useNavigate()

const reset = () => {
  navigate('/');
}
    return (
      <div>
        <button className='reset-btn' onClick={reset}>Reset</button>
        <img src={orads2pic} alt='orads2' className='orads-popup' />
      </div>
    )
}

export const Orads3 = () => {
  const navigate = useNavigate()

const reset = () => {
  navigate('/');
}
    return (
      <div>
        <button className='reset-btn' onClick={reset}>Reset</button>
        <img src={orads3pic} alt='orads3' className='orads-popup' />
      </div>
    )
}

export const Orads4 = () => {
  const navigate = useNavigate()

const reset = () => {
  navigate('/');
}
    return (
      <div>
        <button className='reset-btn' onClick={reset}>Reset</button>
        <img src={orads4pic} alt='orads4' className='orads-popup' />
      </div>
    )
}

export const Orads5 = () => {
  const navigate = useNavigate()

const reset = () => {
  navigate('/');
}
    return (
      <div>
        <button className='reset-btn' onClick={reset}>Reset</button>
        <img src={orads5pic} alt='orads5' className='orads-popup' />
      </div>
    )
}
