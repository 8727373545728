import React, { useEffect, useState } from 'react'
import Questions from '../Questions/Questions'
import { useDispatch, useSelector } from 'react-redux'
import { resetQuestions, selectResearchQuestions, calculateScore, submitResearchQuestions, setQuestion } from '../../features/researchQuestionsSlice'
import './Research.css'
import axios from 'axios'

function Research() {

    if (typeof localStorage.getItem('user') === 'object') {
        const user = {loggedIn: false, username: '', password: ''}
        localStorage.setItem('user', JSON.stringify(user))
        console.log('Declared user variable in local storage.')
    }

    const [loggedIn, setLoggedIn] = useState(JSON.parse(localStorage.getItem('user')).loggedIn ? true : false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [agree, setAgree] = useState(null); 
    const [another, setAnother] = useState(null); 

    const dispatch = useDispatch();

    const researchQuestionsState = useSelector(selectResearchQuestions);
    const researchQuestions = researchQuestionsState.questions;

    const checkForActivity = () => {
        const expireTime = localStorage.getItem('expireTime');

        if (expireTime < Date.now()) {
            logOut();
        }
    }

    const updateExpireTime = () => {
        const expireTime = Date.now() + 1000 * 60 * 10;

        localStorage.setItem('expireTime', expireTime);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            checkForActivity();
        }, 1000 * 5 * 1);

        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        updateExpireTime();

        window.addEventListener('click', updateExpireTime);
        window.addEventListener('mousemove', updateExpireTime);
        window.addEventListener('scroll', updateExpireTime);
        window.addEventListener('keypress', updateExpireTime);
        return () => {
            window.removeEventListener('click', updateExpireTime);
            window.removeEventListener('mousemove', updateExpireTime);
            window.removeEventListener('scroll', updateExpireTime);
            window.removeEventListener('keypress', updateExpireTime);
        }
    }, [])

    useEffect(() => {  
        dispatch(setQuestion({index: 'userID', value: JSON.parse(localStorage.getItem('user')).username}))
    }, [])  

    useEffect(() => {  
        /*if (researchQuestions.presence === '1' && researchQuestions.note === '3') {
            dispatch(setQuestion({index: 'score', value: '5'}))
        }
        if (researchQuestions.presence === '') {
            dispatch(setQuestion({index: 'score', value: 'Not enough information'}))
        } */
       console.log(researchQuestions)
       dispatch(calculateScore())
    }, [researchQuestions])  

    const btnStyle = id => {
        if(researchQuestions[id] === '1') {
          return {
            yes: {
              backgroundColor: 'var(--green)',
              color: 'var(--dark-blue)'
            }
          }
        } else if (researchQuestions[id] == '0') {
          return {
            no: {
              backgroundColor: '#ff4545',
              color: 'var(--dark-blue)'
            }
          }
        } else {
          return {}
        }
      }

    const subject_questions = [
        {
            description: 'Subject Information:',
            style: {
                span: {
                    padding: '0px',
                    margin: '0px',
                    fontSize: '1.4rem'
                }
            }
        },
        {
            description: 'Subject ID:',
            type: 'text',
            callback: (e) => {
                dispatch(setQuestion({index: 'subjectID', value: e.target.value}))
            },
            style: {
                span: {
                    margin: '0px',
                }
            },
            id: 'subjectID'
        },
        {
            description: 'Age:',
            type: 'text',
            style: {
                span: {
                    margin: '0px',
                }
            },
            callback: (e) => {
                dispatch(setQuestion({index: 'age', value: e.target.value}))
            },
            id: 'age'
        },
        {
            description: 'Presence of peritoneal, mesenteric or omental implants, nodularity or irregular thickening, with or without ascites:',
            type: 'boolean',
            callback: (e) => {
                dispatch(setQuestion({index: 'presence', value: e.target.value}))
                /*if (researchQuestions.note === '1') {
                    if (researchQuestions.presence === '1') {
                        dispatch(setQuestion({index: 'score', value: '1'}))
                    } else if (researchQuestions.presence === '0') {
                        dispatch(setQuestion({index: 'score', value: '1'}))
                    }
                } else if (researchQuestions.note === '2') {
                    if (researchQuestions.presence === '1') {
                        dispatch(setQuestion({index: 'score', value: '1'}))
                    } else if (researchQuestions.presence === '0') {
                        dispatch(setQuestion({index: 'score', value: '1'}))
                    }
                } else if (researchQuestions.note === '4') {
                    if (researchQuestions.presence === '1') {
                        dispatch(setQuestion({index: 'score', value: '5'}))
                    } else if (researchQuestions.presence === '0') {
                        dispatch(setQuestion({index: 'score', value: '2'}))
                    }
                } else if (researchQuestions.note === '5') {
                    if (researchQuestions.presence === '1') {
                        dispatch(setQuestion({index: 'score', value: '5'}))
                    } else if (researchQuestions.presence === '0') {
                        dispatch(setQuestion({index: 'score', value: '3'}))
                    }
                } else if (researchQuestions.note === '6') {
                    dispatch(setQuestion({index: 'score', value: 'n/a (peritoneal inclusion cyst do not get an O-RADS MRI score)'}))
                }*/
            }, 
            style: {
                ...btnStyle('presence'),
                span: {
                    margin: '0px'
                }
            },
            id: 'presence'
        },
        {
            description: 'Note what was found on MRI:',
            type: 'dropdown',
            style: {
                span: {
                    margin: '0px',
                },
                select: {
                    marginLeft: '5%',
                    transform: 'scale(1.1)',
                }
            },
            callback: (e) => {
                dispatch(setQuestion({index: 'note', value: e.target.value}))
                /*if (e.target.value === '1') {
                    if (researchQuestions.presence === '1') {
                        dispatch(setQuestion({index: 'score', value: '1'}))
                    } else if (researchQuestions.presence === '0') {
                        dispatch(setQuestion({index: 'score', value: '1'}))
                    }
                } else if (e.target.value === '2') {
                    if (researchQuestions.presence === '1') {
                        dispatch(setQuestion({index: 'score', value: '1'}))
                    } else if (researchQuestions.presence === '0') {
                        dispatch(setQuestion({index: 'score', value: '1'}))
                    }
                } else if (e.target.value === '4') {
                    if (researchQuestions.presence === '1') {
                        dispatch(setQuestion({index: 'score', value: '5'}))
                    } else if (researchQuestions.presence === '0') {
                        dispatch(setQuestion({index: 'score', value: '2'}))
                    }
                } else if (e.target.value === '5') {
                    if (researchQuestions.presence === '1') {
                        dispatch(setQuestion({index: 'score', value: '5'}))
                    } else if (researchQuestions.presence === '0') {
                        dispatch(setQuestion({index: 'score', value: '3'}))
                    }
                } else if (e.target.value === '6') {
                    dispatch(setQuestion({index: 'score', value: 'n/a (peritoneal inclusion cyst do not get an O-RADS MRI score)'}))
                }*/
            },
            options: [
                {description: 'Artifacts present and cannot evaluate the ovaries or lesion', value: '0'},
                {description: 'Normal ovary/ovaries seen on MRI', value: '1'},
                {description: 'Physiologic lesion on MRI (simple, hemorrhagic and proteinaceous cyst ≤3cm in pre-meno)', value: '2'},
                {description: 'Adnexal lesion seen on MRI (Includes ovarian, para ovarian, and fallopian tube)', value: '3'},
                {description: 'Dilated fallopian tube, simple, no solid tissue, thin smooth wall', value: '4'},
                {description: 'Dilated fallopian tube, non-simple fluid OR thick smooth wall', value: '5'},
                {description: 'Peritoneal inclusion cyst', value: '6'}
            ],
            id: 'note'
        },
    ]
    const lesion_questions = [
        {
            description: 'Adnexal Lesion Information:',
            style: {
                span: {
                    padding: '0px',
                    margin: '0px',
                    fontSize: '1.4rem',
                    color: 'var(--green)'
                }
            }
        },
        {
            title: 'Question 1:', 
            description: 'Greatest lesion diameter (cm) in any plane:', 
            callback: (e) => {
                dispatch(setQuestion({index: 'Q1', value: e.target.value}))
            }, 
            type: 'text', 
            style: {}, 
            id: 'Q1',
            value: null
        },
        {
            title: 'Question 2:', 
            description: 'Side of lesion?', 
            callback: (e) => { 
                dispatch(setQuestion({index: 'Q2', value: e.target.value}))
            }, 
            type: 'dropdown', 
            style: {}, 
            id: 'Q2',
            options: [
                {description: 'Right', value: '1'},
                {description: 'Left', value: '2'},
                {description: 'Not able to tell', value: '3'},
            ]
        },
        {
            title: 'Question 3:', 
            description: 'Is there fat or lipid associated within the lesion?', 
            callback: (e) => {
                dispatch(setQuestion({index: 'Q3', value: e.target.value}))
                if(e.target.value === '1') {
                    dispatch(setQuestion({index: 'Q4', value: null}))
                    dispatch(setQuestion({index: 'Q4A', value: null}))
                    dispatch(setQuestion({index: 'Q4B', value: null}))
                    dispatch(setQuestion({index: 'Q4C', value: null}))
                    dispatch(setQuestion({index: 'Q5', value: null}))
                    dispatch(setQuestion({index: 'Q5A', value: null}))
                    dispatch(setQuestion({index: 'Q5B', value: null}))
                } else {
                    dispatch(setQuestion({index: 'Q3A', value: null}))
                    dispatch(setQuestion({index: 'Q3B', value: null}))
                    dispatch(setQuestion({index: 'Q3C', value: null}))
                }
            }, 
            type: 'boolean', 
            style: btnStyle('Q3'), 
            id: 'Q3'
        },
        {
            description: 'A: Is there a Rokitansky nodule?', 
            callback: (e) => {
                dispatch(setQuestion({index: 'Q3A', value: e.target.value}))
            }, 
            type: 'boolean', 
            style: researchQuestions.Q3 === '1' ? {
                ...btnStyle('Q3A'),
                div: {
                    display: 'block'
                }
            } : {
                div: {
                    display: 'none'
                }
            },
            id: 'Q3A'
        },
        {
            description: 'B: Is there a large amount of solid enhancing tissue, not typical for a Rokitansky nodule?', 
            callback: (e) => {
                dispatch(setQuestion({index: 'Q3B', value: e.target.value}))
                /*if (e.target.value === '1') {
                    dispatch(setQuestion({index: 'score', value: 4}))
                } else {
                    dispatch(setQuestion({index: 'score', value: 2}))
                }*/
            }, 
            type: 'boolean', 
            style: researchQuestions.Q3 === '1' ? {
                ...btnStyle('Q3B'),
                div: {
                    display: 'flex'
                }
            } : {
                div: {
                    display: 'none'
                }
            },
            id: 'Q3B'
        },
        {
            title: 'Question 4:',
            description: 'Is there enhancing solid tissue* in the adnexal lesion?',
            callback: (e) => {
                dispatch(setQuestion({index: 'Q4', value: e.target.value}))
                /*if (e.target.value === '0') {
                    dispatch(setQuestion({index: 'Q4A', value: null}))
                    dispatch(setQuestion({index: 'Q4B', value: null}))
                    dispatch(setQuestion({index: 'Q4C', value: null}))
                } else {
                    dispatch(setQuestion({index: 'Q5', value: null}))
                    dispatch(setQuestion({index: 'Q5A', value: null}))
                    dispatch(setQuestion({index: 'Q5B', value: null}))
                }*/
            },
            type: 'boolean',
            style: researchQuestions.Q3 === '0' ? 
            {
                ...btnStyle('Q4'),
                div: {
                    display: 'flex'
                }
            } : 
            {
                div: {
                    display: 'none'
                }
            },
            id: 'Q4'
        },
        {
            description: '*Solid Tissue: Enhancing papillary projection, nodule, irregular seperation/wall, solid lesion. Note: Thin smooth seperations are NOT solid tissue.',
            style: {
                span: {
                    margin: '0px',
                    fontSize: '0.9rem',
                    fontStyle: 'italic'
                }
            }
        },
        {
            description: 'A: What best describes the signal intensity of the solid tissue on T2 and high B-value diffusion weighted images?', 
            callback: (e) => {
                dispatch(setQuestion({index: 'Q4A', value: e.target.value}))
            }, 
            type: 'choice', 
            style: researchQuestions.Q4 === '1' ? {
                div: {
                    display: 'flex'
                }
            } : {
                div: {
                    display: 'none'
                }
            },
            options: [
                {description: 'All solid tissue in the lesion is HOMOGENOUSLY dark in signal', value: '1', style: researchQuestions.Q4A === '1' ? {
                  backgroundColor: 'var(--green)',
                  color: 'var(--dark-blue)'
                } : {} },
                {description: 'Some solid tissue is non-dark (eg. some solid tissue is intermediate or high signal on T2 and/or DWI)', value: '2', style: researchQuestions.Q4A === '2' ? {
                  backgroundColor: 'var(--green)',
                  color: 'var(--dark-blue)'
                } : {}}
            ],
            id: 'Q4A'
        },
        {
            description: 'B: What best describes the morphology of the solid tissue?', 
            callback: (e) => {
                dispatch(setQuestion({index: 'Q4B', value: e.target.value}))
                /*if (researchQuestions.Q4A === '1') {
                    dispatch(setQuestion({index: 'score', value: 2}))
                }*/
            }, 
            type: 'dropdown', 
            style: researchQuestions.Q4 === '1' ? {
                div: {
                    display: 'block'
                }
            } : {
                div: {
                    display: 'none'
                }
            },
            options: [
                {description: 'Irregular septations(s) or wall', value: '1'},
                {description: 'Papillary projection(s)', value: '2'},
                {description: 'Nodule(s)', value: '3'},
                {description: 'Larger solid component(s)', value: '4'},
                {description: 'Solid lesion (at least 80% solid)', value: '5'},
            ],
            id: 'Q4B'
        },
        {
            description: 'C: What best describes the enhancement of the solid tissue?', 
            callback: (e) => {
                dispatch(setQuestion({index: 'Q4C', value: e.target.value}))
                /*if (e.target.value === '1') {
                    dispatch(setQuestion({index: 'score', value: 4}))
                } else if (e.target.value === '2') {
                    dispatch(setQuestion({index: 'score', value: 5}))
                } else if (e.target.value === '3') {
                    dispatch(setQuestion({index: 'score', value: 3}))
                } else if (e.target.value === '4') {
                    dispatch(setQuestion({index: 'score', value: 4}))
                } else if (e.target.value === '5') {
                    dispatch(setQuestion({index: 'score', value: 5}))
                }*/
            }, 
            type: 'dropdown', 
            style: researchQuestions.Q4A === '2' ? {
                div: {
                    display: 'block'
                }
            } : {
                div: {
                    display: 'none'
                }
            },
            options: [
                {description: 'Hypo/isoenhancing to myometrium at 30-40s on non-DCE MRI', value: '1'},
                {description: 'Hyperenhancing to myometrium at 30-40s on non-DCE MRI', value: '2'},
                {description: 'Low risk TIC on DCE MRI', value: '3'},
                {description: 'Intermediate risk TIC on DCE MRI', value: '4'},
                {description: 'High risk TIC on DCE MRI', value: '5'},
            ],
            id: 'Q4C'
        },
        {
            title: 'Question 5:',
            description: 'What best describes the cystic lesion?', 
            callback: (e) => {
                dispatch(setQuestion({index: 'Q5', value: e.target.value}))
            }, 
            type: 'choice', 
            style: researchQuestions.Q4 === '0' ? {
                div: {
                    display: 'block'
                }
            } : {
                div: {
                    display: 'none'
                }
            },
            options: [
                {description: 'Unilocular', value: '0', style: researchQuestions.Q5 === '0' ? {
                    backgroundColor: 'var(--green)',
                    color: 'var(--dark-blue)'
                  } : {} },
                {description: 'Multilocular (at least 1 septation)', value: '1', style: researchQuestions.Q5 === '1' ? {
                    backgroundColor: 'var(--green)',
                    color: 'var(--dark-blue)'
                  } : {} },
            ],
            id: 'Q5'
        },
        {
            description: 'A: What type of fluid is present?', 
            callback: (e) => {
                dispatch(setQuestion({index: 'Q5A', value: e.target.value}))
                /*(if(e.target.value === '1' || e.target.value === '2' && researchQuestions.Q5 === '0') {
                    dispatch(setQuestion({index: 'score', value: 2}))
                }*/
            }, 
            type: 'dropdown', 
            style: researchQuestions.Q5 ? {
                div: {
                    display: 'block',
                },
                select: {
                    width: 'auto'
                }
            } : {
                div: {
                    display: 'none'
                }
            },
            options: [
                {description: 'Simple', value: '1'},
                {description: 'Endometriotic', value: '2'},
                {description: 'Hemorrhagic OR proteinaceous', value: '3'},
                {description: 'Simple AND endometriotic', value: '4'},
                {description: 'Combination of simple OR endometriotic AND hemorrhatic / proteinaceous', value: '5'},
            ],
            id: 'Q5A'
        },
        {
            description: 'B: Is there wall enhancement?', 
            callback: (e) => {
                dispatch(setQuestion({index: 'Q5B', value: e.target.value}))
                /*if (e.target.value === '1' && researchQuestions.Q5 === '0' && (researchQuestions.Q5A === '3' || researchQuestions.Q5A === '5')) {
                    dispatch(setQuestion({index: 'score', value: 3}))
                }
                else if (researchQuestions.Q5A === '1' || researchQuestions.Q5a === '4' || researchQuestions.Q5A === '2' && researchQuestions.Q5 === '0') {
                    dispatch(setQuestion({index: 'score', value: 2}))
                } 
                else if (researchQuestions.Q5 === '1') {
                    dispatch(setQuestion({index: 'score', value: 3}))
                }
                else {
                    dispatch(setQuestion({index: 'score', value: 2}))
                }*/
            }, 
            type: 'boolean', 
            style: researchQuestions.Q5 ? {
                ...btnStyle('Q5B'),
                div: {
                    display: 'block'
                }
            } : {
                div: {
                    display: 'none'
                }
            },
            id: 'Q5B'
        }
    ]

    const orads_score = [
        {
            description: 'O-RADS MRI score: ' + researchQuestions.score,
            style:
                {
                    span: {
                        margin: '0px',
                        fontSize: '1.4rem',
                        color: 'var(--green)'
                    },
                    div: {
                        display: 'block'
                    }
                }
        },
        {
            description: 'Do you agree?',
            type: 'boolean',
            callback: (e) => {
                setAgree(e.target.value)
                console.log(agree)
            },
            type: 'boolean',
            style: researchQuestions.score !== 'Not enough information' ? {
                yes: agree === '1' ? {
                    backgroundColor: 'var(--green)',
                    color: 'var(--dark-blue)'
                } : {}, 
                no: agree === '0' ? {
                    backgroundColor: '#ff4545',
                    color: 'var(--dark-blue)'
                } : {} ,
                span: {
                    margin: '0px',
                }
            } : {
                div: {
                    display: 'none'
                }
            }
            ,
            id: 'agree'
        },
        {
            description: 'Please write your ORADS score in the box below and additional information if needed:',
            type: 'text',
            callback: (e) => {
                dispatch(setQuestion({index: 'disagree', value: e.target.value}))
            },
            style: 
            {
                div: {
                    display: agree === '0' ? 'block' : 'none'
                },
                span: {
                    margin: '0px',
                }
            },
            id: 'disagree'
        },
        {
            description: 'Does the patient have another lesion?',
            type: 'boolean',
            callback: async (e) => {
                console.log(e.target.value)
                setAnother(e.target.value)
                if (e.target.value === '0') {
                    setAgree(null)
                    dispatch(submitResearchQuestions({payload: researchQuestions})).then(() => {
                        const UserID = researchQuestions.userID;
                        dispatch(resetQuestions());
                        dispatch(setQuestion({index: 'userID', value: UserID}));
                })
                } else {
                    dispatch(submitResearchQuestions({payload: researchQuestions})).then(() => {
                        const nextSubjectID = researchQuestions.subjectID ? researchQuestions.subjectID.includes('.') ? researchQuestions.subjectID.split('.')[0] + '.' + (parseInt(researchQuestions.subjectID.split('.')[1]) + 1) : researchQuestions.subjectID + '.1' : null;
                        const subjectAge = researchQuestions.age;
                        const UserID = researchQuestions.userID;
                        dispatch(resetQuestions())
                        dispatch(setQuestion({index: 'userID', value: UserID}));
                        dispatch(setQuestion({index: 'subjectID', value: nextSubjectID}));
                        dispatch(setQuestion({index: 'age', value: subjectAge}));
                        console.log(researchQuestions)
                    })
                }
                setAgree(null);
                setAnother(null);
            }, 
            style: {
                yes: another === '1' ? {
                    backgroundColor: 'var(--green)',
                    color: 'var(--dark-blue)'
                } : {}, 
                no: another === '0' ? {
                    backgroundColor: '#ff4545',
                    color: 'var(--dark-blue)'
                } : {} ,
                div: {
                    display: agree ? 'block' : 'none'
                }
            },
            id: 'another'
        },
    ]

    const onChange = (e) => {
        if (e.target.id === 'username') {
            setUsername(e.target.value)
        } else if(e.target.id === 'password') {
            setPassword(e.target.value)
        }
    }

    const login = async () => {
        setLoggedIn('pending');
        const  loginData = await axios.get('https://script.google.com/macros/s/AKfycbwjr9dxkX1_3DX__4bL8_SOEqTlCtdw_vFigHjXfA5mje32f1dLjVcgDDZ9WstNNAw7SQ/exec')
        .then(res => {
            setUsername('');
            setPassword('');
            return res.data
        });
        console.log(loginData);
        loginData.forEach(user => {
            if (user.username === username) {
                if (user.password === password) {
                    setLoggedIn(true)
                    dispatch(setQuestion({index: 'userID', value: username}))
                    const userData = {loggedIn: true, username, password}
                    localStorage.setItem('user', JSON.stringify(userData))
                } else {
                    console.log('Incorrect Password')
                    setLoggedIn(false)
                }
            } else {
                console.log('Incorrect Username')
                setLoggedIn(false)
            }
        })
    }

    const logOut = () => {
        const userData = {loggedIn: false, username: '', password: ''}
        localStorage.setItem('user', JSON.stringify(userData));
        setLoggedIn(false)
        dispatch(resetQuestions())
        console.log('Logged Out!')
    }

    const reset = () => {
        dispatch(resetQuestions())
    }


    if (loggedIn == true) {
        return (
            <div id='research'>
                <div>
                    <Questions questionValues={researchQuestions} questions={subject_questions} />
                    <Questions questionValues={researchQuestions} questions={lesion_questions} style={researchQuestions.note === '3' ? {display: 'block'} : {display: 'none'}} />
                    {
                        researchQuestionsState.loading === 'pending' ? 
                        <div className='loading-orads'>
                            <div id='loading-wrapper' >
                                <div id='login-loading-circle-1'/>
                                <div id='login-loading-circle-2'/>
                                <div id='login-loading-circle-3'/>
                                <div id='login-loading-circle-4'/>
                                <div id='login-loading-text' style={{
                                    transform: 'scale(2.75)',
                                }}>Saving...</div>
                            </div> 
                        </div>:
                        <Questions questionValues={researchQuestions} questions={orads_score} />
                    }
                </div>
                <div id='logout-wrapper'>
                    <button id='logOut' onClick={reset}>Reset</button>
                    <button id='logOut' onClick={logOut}>Log out</button>
                </div>
            </div>
        )
    } else if (loggedIn === 'pending') {
        return (
            <div id='loading-wrapper'>
                <div id='login-loading-circle-1'/>
                <div id='login-loading-circle-2'/>
                <div id='login-loading-circle-3'/>
                <div id='login-loading-circle-4'/>
                <div id='login-loading-text'>Loading...</div>
            </div>
        )
    } else {
        return (
            <div id='login-wrapper'>
                <input id='username' type='text' onChange={onChange} value={username} placeholder='Username'/>
                <input id='password' type='text' onChange={onChange} value={password} placeholder='Password'/>
                <button id='login' onClick={login}>Login</button>
            </div>
        )
    }
}

export default Research